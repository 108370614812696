import React from "react"
import styled from "styled-components"
import { UserFeedbackMessage } from "../atoms/userFeedback"
import OwnershipModalButton from "../modals/ownershipModalButton"
import { BsCaretDown, BsCaretDownFill, BsCaretUpFill } from "react-icons/bs"
import { FilterState, SortState } from "./availableWeeks"
import { filterWeeksForSale } from "../../utils/filterWeeksForSale"
import { sortWeeksForSale } from "../../utils/sortWeeksForSale"
import formatCurrency from "../../utils/formatCurrency"
import { WeekForSale } from "../../hooks/utils/useGetWeeksForSale"

type WeeksForSaleTableProps = {
  weeks: WeekForSale[]
  sortState: SortState
  handleSorting: (key: string) => void
  filterState: FilterState
}

const WeeksForSaleTable: React.FC<WeeksForSaleTableProps> = ({
  weeks,
  sortState,
  filterState,
  handleSorting,
}) => {
  const headers = ["week", "room", "type", "price"]
  const clickable = key => key === "price" || key === "week"
  const filteredWeeks = filterWeeksForSale(filterState, weeks)
  const sortedWeeks = sortWeeksForSale(sortState, filteredWeeks)
  const getSortIcon =
    sortState.order === "ASC" ? <BsCaretUpFill /> : <BsCaretDownFill />

  const getWeekData = week => {
    let iterator = 0
    let weekData = {}
    const tds = Object.keys(week).map(weekEntry => {
      weekData = { ...weekData, [weekEntry]: week[weekEntry] }
      iterator += 1
      return (
        <TD key={iterator}>
          {weekEntry === "price"
            ? formatCurrency(week[weekEntry])
            : week[weekEntry]}
        </TD>
      )
    })
    return { tds, weekData }
  }

  return (
    <Container>
      <TableWrapper>
        {weeks.length === 0 ? (
          <UserFeedbackMessage style={{ display: "block" }} type={"success"}>
            Couldn't retrieve available weeks. Please contact us at{" "}
            <Anchor href="mailto:sales@playalinda.com">
              sales@playalinda.com
            </Anchor>{" "}
            for information on current available weeks.
          </UserFeedbackMessage>
        ) : (
          <VacancyTable cellSpacing="0">
            <tbody>
              <TR>
                {headers.map(key => {
                  return (
                    <TH
                      key={key}
                      onClick={() => handleSorting(key)}
                      clickable={clickable(key)}
                    >
                      {clickable(key) && key === sortState.sortBy ? (
                        <HeaderSpan>
                          {key} {getSortIcon}
                        </HeaderSpan>
                      ) : clickable(key) ? (
                        <HeaderSpan>
                          {key} <NonActiveIcon />
                        </HeaderSpan>
                      ) : (
                        <HeaderSpan>{key}</HeaderSpan>
                      )}
                    </TH>
                  )
                })}
              </TR>

              {sortedWeeks.map((week, i) => {
                const { tds, weekData } = getWeekData(week)
                return (
                  <TR key={i}>
                    {tds}
                    <TD>
                      <OwnershipModalButton
                        data={weekData}
                        modalType="openOption"
                        text="Info"
                      />
                    </TD>
                  </TR>
                )
              })}
            </tbody>
          </VacancyTable>
        )}
      </TableWrapper>
    </Container>
  )
}

export default WeeksForSaleTable

const Container = styled.div``

const Anchor = styled.a`
  :active,
  :visited,
  :hover {
    color: var(--primary-text);
  }
  position: relative;
  cursor: pointer;
  text-decoration: none;
  ::after {
    transition: width 300ms, transform 300ms;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0.5px;
    background-color: var(--primary-text);
  }
  :hover::after {
    transition: width 300ms, transform 300ms;
    width: 100%;
  }
`
const TableWrapper = styled.div`
  @media (max-width: 570px) {
    overflow: scroll;
  }
`
const VacancyTable = styled.table`
  padding: 40px 8px;
  width: 70%;
  min-width: 570px;
  margin: 0 auto;
  table-layout: fixed;
`
const TR = styled.tr`
  height: 60px;
  &:nth-child(even) {
    background-color: #eceae8;
  }
`
const TH = styled.th<{ clickable: boolean }>`
  text-align: left;
  color: var(--primary-text);
  padding: 16px 0;
  text-transform: capitalize;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "initial")};
  &:first-child {
    padding-left: 16px;
  }
`

const TD = styled.td`
  color: var(--primary-text);
  padding: 16px 0;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    text-align: right;
    padding-right: 16px;
  }
`

const HeaderSpan = styled.span`
  width: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg {
    font-size: 1.1rem;
    position: relative;
    top: 2px;
  }
`

const NonActiveIcon = styled(BsCaretDown)`
  opacity: 0.5;
`
