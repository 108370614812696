import React, { useState } from "react"
import { useGetWeeksForSale } from "../../hooks/utils/useGetWeeksForSale"
import styled from "styled-components"
import WeeksForSaleTable from "./WeeksForSaleTable"
import TableFilters from "./TableFilters"
import Loading from "./Loading"

export type SortState = { sortBy: string; order: "ASC" | "DESC" }
export type FilterState = { filterType: string; filterBy: string | undefined }

const AvailableWeeks = ({ title }) => {
  const { weeks, loading } = useGetWeeksForSale()

  const [sortState, setSortState] = useState<SortState>({
    sortBy: "week",
    order: "DESC",
  })
  const [filterState, setFilterState] = useState<FilterState>({
    filterBy: undefined,
    filterType: undefined,
  })

  const handleSorting = key => {
    if (key === "price" || key === "week") {
      const { sortBy, order } = sortState
      if (key === sortBy && order === "ASC") {
        return setSortState({ ...sortState, order: "DESC" })
      } else if (key === sortBy && order === "DESC") {
        return setSortState({ ...sortState, order: "ASC" })
      }
      return setSortState({ sortBy: key, order: "ASC" })
    }
  }
  const handleFilter = e => {
    const filterBy = e.target.value
    if (filterBy === "0") {
      return setFilterState({ filterBy: undefined, filterType: undefined })
    }
    return setFilterState({ filterType: e.target.id, filterBy: e.target.value })
  }
  const getOptions = (from: string) => {
    return Array.from(new Set(weeks.map(week => week[from])))
  }

  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: title.html }} />
      <TableFilters handleFilter={handleFilter} getOptions={getOptions} />
      {loading ? (
        <Loading />
      ) : (
        <WeeksForSaleTable
          weeks={weeks}
          handleSorting={handleSorting}
          sortState={sortState}
          filterState={filterState}
        />
      )}
    </Container>
  )
}

export default AvailableWeeks

const Container = styled.div`
  padding: 80px 0;
`

const Title = styled.div`
  font-size: 2rem;
  font-family: "Canela Light", sans-serif;
  text-align: center;
  color: var(--primary-text);
`
