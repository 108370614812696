import { FilterState } from "../components/ownership/availableWeeks"
import { WeekForSale } from "../hooks/utils/useGetWeeksForSale"

export const filterWeeksForSale = (
  filterState: FilterState,
  weeks: WeekForSale[]
) => {
  const { filterType, filterBy } = filterState
  if (!filterBy) return weeks

  return weeks.filter(week => {
    return week[filterType] === filterBy
  })
}
