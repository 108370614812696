import React from "react"
import styled from "styled-components"

const Video = ({ video_url }) => {
  return (
    <Iframe
      src={
        video_url.embed_url +
        "&autoplay=1&muted=1&controls=1&playsinline=1&loop=1"
      }
      allowFullScreen
    />
  )
}

export default Video

const Iframe = styled.iframe`
  height: 560px;
  margin: -40px auto 80px;
  width: 100%;
  border: none;
`
