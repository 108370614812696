import { useEffect, useState } from "react"

export type WeekForSale = {
  week: string
  room: string
  type: string
  price: string
  phase?: string
}

type Props = { loading: boolean; weeks: WeekForSale[] }

export const useGetWeeksForSale = (): Props => {
  const [loading, setLoading] = useState(false)
  const [htmlString, setHtmlString] = useState<string>("")

  const url = "/.netlify/functions/weeksForSale"

  useEffect(() => {
    setLoading(true)
    fetch(url)
      .then(async res => res.text())
      .then(html => {
        setHtmlString(html)
        setLoading(false)
      })
  }, [])

  if (typeof window !== "undefined") {
    const domparser = new DOMParser()
    const htmlDoc = domparser.parseFromString(htmlString, "text/html")
    const tableElement = htmlDoc.getElementById("GridView1")
    if (!tableElement) {
      return { loading, weeks: [] }
    }

    const tableHeadings = ["week", "phase", "room", "type", "price"]
    // Get table data
    const roomsData = Array.from(tableElement.getElementsByTagName("tr"))
      .splice(1)
      .map(tr => {
        const roomData = []
        Array.from(tr.children).forEach(td => {
          // @ts-ignore
          roomData.push(td.innerText)
        })
        return roomData
      })
    // add headings as key to room data
    const weeks: WeekForSale[] = []
    roomsData.map(roomData => {
      let room: WeekForSale = {
        phase: "",
        week: "",
        room: "",
        type: "",
        price: "",
      }
      roomData.map((data, i) => {
        room = { ...room, [tableHeadings[i]]: data }
      })
      const { phase, ...weekForSale } = room
      weeks.push(weekForSale)
    })
    return { loading, weeks }
  }
  return { loading, weeks: [] }
}
