import React, { ChangeEvent } from "react"
import styled from "styled-components"

type TableFilterProps = {
  handleFilter: (e: ChangeEvent<HTMLSelectElement>) => void
  getOptions: (optionType: string) => any[]
}

const TableFilters: React.FC<TableFilterProps> = ({
  handleFilter,
  getOptions,
}) => {
  return (
    <Container>
      <Select
        aria-label="room type"
        name="type"
        id="type"
        onChange={e => handleFilter(e)}
      >
        <option value="0">Room Type</option>
        {getOptions("type").map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <Select aria-label="week" name="week" id="week" onChange={handleFilter}>
        <option value="0">Week</option>
        {getOptions("week").map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </Container>
  )
}

export default TableFilters

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  padding: 40px 0;
  grid-gap: 0 40px;
  @media (max-width: 720px) {
    grid-template-columns: auto;
    grid-gap: 16px 0;
    padding: 16px 0;
  }
`

const Select = styled.select`
  background: var(--primary-white);
  color: var(--primary-text);
  padding: 8px;
  width: 200px;
  font-family: "Moderat Regular", sans-serif;
  cursor: pointer;
`
