import React from "react"
import styled from "styled-components"

const Loading = () => {
  return <Container>Loading weeks for sale data..</Container>
}

export default Loading

const Container = styled.div`
  width: max-content;
  margin: 88px auto;
  color: var(--primary-text);
`
