import { WeekForSale } from "../hooks/utils/useGetWeeksForSale"
import { SortState } from "../components/ownership/availableWeeks"

export const sortWeeksForSale = (
  sortState: SortState,
  weeks: WeekForSale[]
) => {
  const { sortBy, order } = sortState
  return weeks.sort((a, b) => {
    return order === "ASC"
      ? parseFloat(a[sortBy]) - parseFloat(b[sortBy])
      : parseFloat(b[sortBy]) - parseFloat(a[sortBy])
  })
}
